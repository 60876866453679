import React, { Fragment, useEffect, useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import GavelIcon from "@mui/icons-material/Gavel";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CategoryIcon from "@mui/icons-material/Category";
import { useHistory, useLocation } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Dashboard from "@mui/icons-material/Dashboard";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import { useDispatch, useSelector } from "react-redux";
import Http from "../utils/Http";
import { logout, setUser } from "../modules/auth/store/actionCreator";
import LogoutIcon from "@mui/icons-material/Logout";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { showNotifications } from "./notifications/store/actionCreator";
import { handleErrorResponse } from "../utils/helpers";
import SettingsIcon from "@mui/icons-material/Settings";
import { getAllSettings } from "../modules/admin/settings/service";
import { Helmet } from "react-helmet";

function Sidebar() {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (Object.values(settings).length === 0) {
      dispatch(getAllSettings());
    }
  }, [settings]); // eslint-disable-line

  useEffect(() => {
    Http.get("user").then((res) => {
      if (res.data.data) {
        dispatch(setUser(res.data.data));
      }
    });
  }, []); // eslint-disable-line

  const handleNavigate = (path) => {
    if (path.includes("winners")) {
      window.open(path, "_blank");
    } else {
      history.push(path);
    }
  };

  const links = [
    {
      path: "/winners",
      name: "Winners",
      icon: <Dashboard sx={{ fontSize: 20 }} />,
    },
    {
      path: "/admin/awards",
      name: "Awards",
      icon: <Dashboard sx={{ fontSize: 20 }} />,
    },
    {
      path: "/admin/dashboard",
      name: "Scoreboard",
      icon: <Dashboard sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/monitoring",
      name: "Monitoring",
      icon: <LegendToggleIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },

    {
      path: "/admin/individual-score",
      name: "Individual Score",
      icon: <LegendToggleIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },

    {
      path: "/admin/tie-breaker",
      name: "Tie Breaker",
      icon: <InboxIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/results",
      name: "Results",
      icon: <InboxIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/candidates",
      name: "Candidates",
      icon: <PeopleOutlineIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/users",
      name: "Users",
      icon: <GavelIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/category",
      name: "Category",
      icon: <CategoryIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/winner-type",
      name: "Winner Type",
      icon: <ListAltIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },

    {
      path: "/admin/calculation",
      name: "System Calculation",
      icon: <ListAltIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/settings",
      name: "Settings",
      icon: <SettingsIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
    {
      path: "/admin/records/judges",
      name: "Records",
      icon: <ListAltIcon sx={{ fontSize: 20 }} />,
      roles: [0, 1],
    },
  ];

  const handleLogout = () => {
    setShowLogoutModal(!showLogoutModal);
  };

  const handleLogoutConfirm = () => {
    setLoading(true);
    Http.post("logout")
      .then((res) => {
        if (res.data.revoke) {
          dispatch(logout());
          dispatch(showNotifications("Successfully Logout"));
          history.push("/");
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(showNotifications(handleErrorResponse(err), "error"));
      });
  };

  return (
    <Fragment>
      {settings.app_icon && settings.app_icon.url && (
        <Helmet>
          <link rel="icon" href={settings.app_icon.url} />
        </Helmet>
      )}
      <List>
        {links
          .filter(({ roles }) => {
            if (roles) {
              return roles.includes(user.type);
            }

            return true;
          })
          .map(({ name, icon, path }, index) => {
            return (
              <ListItem key={index} disablePadding>
                <ListItemButton
                  sx={{ pt: "4px", pb: "4px" }}
                  onClick={() => handleNavigate(path)}
                  selected={location.pathname.includes(path)}
                >
                  <ListItemIcon sx={{ minWidth: 35 }}>{icon}</ListItemIcon>
                  <ListItemText
                    sx={{
                      "& span": {
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#54667a",
                      },
                    }}
                    primary={name}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        <ListItem disablePadding>
          <ListItemButton sx={{ pt: "4px", pb: "4px" }} onClick={handleLogout}>
            <ListItemIcon sx={{ minWidth: 35 }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                "& span": {
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#54667a",
                },
              }}
              primary="Logout"
            />
          </ListItemButton>
        </ListItem>
      </List>
      <ConfirmationDialog
        message="Are you sure you want to logout?"
        title="Message"
        open={showLogoutModal}
        onClose={handleLogout}
        onConfirm={handleLogoutConfirm}
        loading={loading}
      />
    </Fragment>
  );
}

export default Sidebar;
