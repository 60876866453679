import { LOG_OUT, SET_USER } from "./actionTypes";

export const setUser = (payload) => {
  return {
    type: SET_USER,
    payload,
  };
};

export const logout = () => {
  return {
    type: LOG_OUT,
  };
};
