import Http from "../../../utils/Http";
import { setSettings } from "./store/actionCreators";

export const getAllSettings = () => {
  return (dispatch) => {
    return Http.get("settings/all").then((res) => {
      if (res.data) {
        localStorage.setItem("settings", JSON.stringify(res.data));
        dispatch(setSettings(res.data));
      }

      return res.data;
    });
  };
};

export const getSetting = (params) => {
  return Http.get("settings", { params });
};

export const getAll = () => {
  return Http.get("admin/settings/all");
};

export const updateSetting = (id, params) => {
  return Http.post(`settings/update/${id}`, params);
};
