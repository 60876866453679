const publicRoutes = [
  {
    path: "/",
    component: "modules/admin/login/pages/Login",
  },

  {
    path: "/individual-score/:category_id/:category_name",
    component: "modules/admin/individual-score/pages/IndividualScore",
  },

  {
    path: "/tabulation/:category_id",
    component: "modules/admin/dashboard/pages/Tabulation",
  },
  {
    path: "/tabulation/rank/:name",
    component: "modules/admin/dashboard/pages/Tabulation",
  },
  {
    path: "/admin/results/print",
    component: "modules/admin/results/pages/PrintResult",
  },
  {
    path: "/admin/results/per-category/print",
    component: "modules/admin/results/prints/CategoryRawScorePrint",
  },
  {
    path: "/admin/results/all-category/print",
    component: "modules/admin/results/prints/CategoryResultPrint",
  },
  {
    path: "/winners",
    component: "modules/admin/winners/pages/Winners",
  },
  {
    path: "/winners/:group",
    component: "modules/admin/winners/pages/WinnerListCarousel",
  },
  {
    path: "/admin/tie-breaker/print",
    component: "modules/admin/top/pages/PrintResults",
  },
];

const routes = [
  ...publicRoutes,

  {
    path: "/admin/individual-score",
    component: "modules/admin/individual-score/pages/CategoryList",
    auth: true,
  },
  {
    path: "/admin/tie-breaker",
    component: "modules/admin/top/pages/TopTieBreaker",
    auth: true,
  },
  {
    path: "/admin/dashboard",
    component: "modules/admin/dashboard/pages/Dashboard",
    auth: true,
  },
  {
    path: "/admin/results",
    component: "modules/admin/results/pages/Results",
    auth: true,
  },
  {
    path: "/admin/results/per-category",
    component: "modules/admin/results/pages/CategoryRawScore",
    auth: true,
  },
  {
    path: "/admin/results/all-category",
    component: "modules/admin/results/pages/CategoryResults",
    auth: true,
  },
  {
    path: "/admin/candidates",
    component: "modules/admin/candidates/pages/Candidates",
    auth: true,
  },
  {
    path: "/admin/users",
    component: "modules/admin/users/pages/Users",
    auth: true,
  },
  {
    path: "/admin/category",
    component: "modules/admin/category/pages/Category",
    auth: true,
  },
  {
    path: "/admin/category/:cat_id/criteria",
    component: "modules/admin/criteria/pages/Criteria",
    auth: true,
  },
  {
    path: "/admin/category/:cat_id/sub-category/:sub_id/criteria",
    component: "modules/admin/criteria/pages/Criteria",
    auth: true,
  },
  {
    path: "/admin/category/:id/sub-category",
    component: "modules/admin/category/pages/SubCategory",
    auth: true,
  },
  {
    path: "/admin/settings",
    component: "modules/admin/settings/pages/Settings",
    auth: true,
  },
  {
    path: "/admin/records/:type",
    component: "modules/admin/records/pages/Records",
    auth: true,
  },
  {
    path: "/admin/calculation",
    component: "modules/admin/calculation/pages/Calculation",
    auth: true,
  },
  {
    path: "/admin/winner-type",
    component: "modules/admin/winner-type/pages/WinnerType",
    auth: true,
  },
  {
    path: "/admin/monitoring",
    component: "modules/admin/monitoring/pages/Monitoring",
    auth: true,
  },
  {
    path: "/admin/settings",
    component: "modules/admin/settings/pages/Settings",
    auth: true,
  },
  {
    path: "/admin/awards",
    component: "modules/admin/awards/pages/Awards",
    auth: true,
  },
];

export default routes;
